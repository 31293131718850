import { handleError } from "../utils/errorsUtil";
import { AppDispatch } from "../store";
import { t } from 'i18next'

export const parseQrData = (data?: string) => (dispatch: AppDispatch) => {
  if (data) {
    const [type, res] = data.split('=')
    if (type !== 'otc.qrcode') {
      dispatch(handleError({ errorMessage: t("errors.scanData") }))
      return false
    }
    if (res) {
      return res
    }
    return false
  }
  return false
}

export const normalizeCurrencyType = (currency: string) => { 
  return currency.toUpperCase();
}

export const normalizeCoinType = (coin: string) => {
 return coin.replace(/_/g, "").toUpperCase();
}

export const historySourceCheck = (source: string) => {
  if (source === "buy") {
    return t("history.buy");
  }
  if (source === "sell") {
    return t("history.sell");
  }
  return t("history.manual"); 
}

export const normalizeUpdateAmount = (source: string, updateAmount: number | null) => {
  let prefix = '-';

  if (source === "buy") {
    prefix = '+';
  }
  return `${prefix} ${updateAmount?.toFixed(2) ?? ''}`; 
}

export const normalizeCashSnapshot = (cashSnapshot: number) => {
  return cashSnapshot.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}