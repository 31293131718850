import React, { useCallback } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  TextField,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import CustomButton from "../../Shared/CustomButton/CustomButton";
import style from "./CashAmountModal.module.css";
import { useTranslation } from "react-i18next";
import { openCashAmountModal } from "../../../store/modal/modalSlice";
import {
  selectCashAmount,
  selectCashAmountCurrency,
} from "../../../store/CashAmount/selectors";
import { selectCashAmountModal } from "../../../store/modal/selectors";
import { cashAmountSchema } from "./cashAmountSchema";
import { useFormik } from "formik";
import { updateCashAmount } from "../../../store/CashAmount/cashAmountSlice";
import { normalizeCurrencyType } from "../../../helpers";
import Loader from "../../Shared/Loader/Loader";

interface FormValues {
  cashAmount: string;
}

declare module '@mui/material/TextField' {
  interface ButtonPropsVariantOverrides {
    cashAmount: true;
  }
}

const CashAmountModal = () => {
  const dispatch = useAppDispatch();
  const open = useAppSelector(selectCashAmountModal);
  const cashAmount = useAppSelector(selectCashAmount);
  const currency = useAppSelector(selectCashAmountCurrency);
  const { t } = useTranslation();

  const closeModal = useCallback(() => {
    dispatch(openCashAmountModal(false));
  }, []);

  const submitForm = async (value: FormValues) => {
    await dispatch(updateCashAmount(value.cashAmount));
    closeModal();
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      cashAmount: cashAmount.toString(),
    },
    validationSchema: cashAmountSchema,
    onSubmit: submitForm,
  });

  const renderButtons = () => {
    return (
      <Box className={style.buttons_box}>
        <CustomButton
          height="48px"
          width="112px"
          onClick={closeModal}
          variant={"neutral"}
        >
          {t("buttons.skip")}
        </CustomButton>

        <CustomButton
          type={"submit"}
          height="48px"
          width="132px"
          variant="contained"
        >
          {t("buttons.update")}
        </CustomButton>
      </Box>
    );
  };
  
  if (!cashAmount) return <Loader/>

  return (
    <Dialog
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
      open={open}
      onClose={closeModal}
    >
      <DialogContent className={style.container}>
        <Box
          component="form"
          onSubmit={formik.handleSubmit}
          className={style.box}
        >
          <DialogContentText
            color={"#FFFFFF"}
            lineHeight={"40px"}
            fontSize={"32px"}
            fontWeight={500}
          >
            Please update your available cash amount
          </DialogContentText>
          <Box className={style.currentAmountBlock}>
            <DialogContentText
              className={style.currentAmountText}
              margin={"auto 0px"}
              color={"#FFFFFF"}
              fontSize={"16px"}
              fontWeight={500}
            >
              Current amount:
            </DialogContentText>
            <DialogContentText
              color={"#FFFFFF"}
              lineHeight={"32px"}
              fontSize={"32px"}
              fontWeight={600}
            >
              {normalizeCurrencyType(currency)} {cashAmount}
            </DialogContentText>
          </Box>
          <Box className={style.row_box}>
            <DialogContentText
              margin={"auto 0px"}
              color={"#FFFFFF"}
              fontSize={"16px"}
              fontWeight={500}
            >
              Actual cash amount:
            </DialogContentText>
            <TextField
              variant="standard"
              InputProps={{disableUnderline: true}}
              id="cashAmount"
              name="cashAmount"
              value={formik.values.cashAmount.trim()}
              placeholder={cashAmount.toString()}
              type="text"
              error={Boolean(formik.errors.cashAmount)}
              onChange={formik.handleChange}
            />
          </Box>
          {renderButtons()}
        </Box>
      </DialogContent>
    </Dialog>
  );
};
export default CashAmountModal;
