import React from "react";
import { Box, Typography } from "@mui/material";
import styles from "./Login.module.css";
import LoginForm from "./LoginForm/LoginForm";
import { useTranslation } from "react-i18next";

const Login = () => {
  const { t } = useTranslation();

  return (
    <Box className={styles.container}>
      <Box textAlign={"center"} marginBottom={"40px"}>
        <Typography fontWeight={600} fontSize={"32px"} marginBottom={"10px"}>{t("login.title")}</Typography>
        <Typography sx={{ opacity: "0.8" }}>{t("login.subtitle")}</Typography>
      </Box>
      <LoginForm />
    </Box>
  );
};

export default Login;