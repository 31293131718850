import React, { useEffect, useState } from "react";
import Breadcrumbs from "./Breadcrumbs/Breadcrumbs";
import { Box, Typography } from "@mui/material";
import styles from "./Details.module.css";
import PaymentInfo from "./PaymentInfo/PaymentInfo";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { selectPayment } from "../../store/Details/selectors";
import { getPaymentDetails } from "../../store/Details/detailsSlice";
import Loader from "../Shared/Loader/Loader";
import DetailsFooter from "./DetailsFooter/DetailsFooter";
import { RouteConstants } from "../../routes/routes-constant";


const Details = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const paymentInfo = useAppSelector(selectPayment);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  

  const getDetails = async () => {
    const { res } = await dispatch(getPaymentDetails(params?.token || ""));
    setLoading(false);
    if (!res) navigate(RouteConstants.PORTAL_PATH);
  };

  useEffect(() => {
    getDetails();
  }, []);


  const renderPaymentDetails = () => {
    return paymentInfo?.map(el => {
      return (
        <Box className={styles.box} key={el.name}>
            <Typography className={styles.name} fontWeight={500} fontSize={"16px"}>{el.name}</Typography>
            <Typography fontSize={"32px"} fontWeight={600}>{el.id}</Typography>
        </Box>
      );
    });
  };

  if (loading) return <Loader />;

  return (
    <Box className={styles.main_box}>
      <Box mt={"30px"} p={"0 15px"}>
        <Breadcrumbs />
        <Box className={styles.main_container}>
          <Box className={styles.container}>
            {renderPaymentDetails()}
          </Box>
            <PaymentInfo />
          </Box>
        </Box>
      <DetailsFooter />
    </Box>
  );
};


export default Details;