import React, { useCallback, useState } from "react";
import { Box } from "@mui/material";
import CustomButton from "../../Shared/CustomButton/CustomButton";
import { useNavigate, useParams } from "react-router-dom";
import { RouteConstants } from "../../../routes/routes-constant";
import ArrowIcon from "../../../assets/arrow-left.svg";
import WithdrawCash from "../../../assets/withdraw-cash.svg";
import SendCoins from "../../../assets/send-coins.svg";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { finishPayment } from "../../../store/Details/detailsSlice";
import { selectFinishLoading, selectPaymentInfo } from "../../../store/Details/selectors";
import style from "./DetailsFooter.module.css";
import { useTranslation } from "react-i18next";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import { openConfirmationModal } from "../../../store/modal/modalSlice";

const DetailsFooter = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const loading = useAppSelector(selectFinishLoading);
  const { token, operation } = useParams();
  const { t } = useTranslation();
  const info = useAppSelector(selectPaymentInfo);

  const goToHome = () => navigate(RouteConstants.PORTAL_PATH);

  const openModal = useCallback(() =>{
    dispatch(openConfirmationModal(true))
  }, [])

  const renderFinishButton = () => {  
    return (
      <CustomButton
      variant="contained"
      color={info.payment_type === "buy" ? "secondary" : "primary"}
      height="48px"
      width="175px"
      onClick={openModal}
      loading={loading}
      disabled={loading}
    >
      <img style={{ marginRight: "10px", height: info.payment_type === "buy" ? "18px" : "20px"}} src={info.payment_type === "buy" ? SendCoins : WithdrawCash} alt="send" />
      {info.payment_type === "buy" ? t("buttons.send_coins") : t("buttons.withdraw_cash")}
    </CustomButton>
    )
  }

  return (
    <Box className={style.container}>
      <CustomButton
        variant={"back"}
        height="48px"
        width={"128px"}
        onClick={goToHome}
      >
        <img style={{ marginRight: "10px", height: "24px" }} src={ArrowIcon} alt="arrow" />
        {t("buttons.back")}
      </CustomButton>
      {renderFinishButton()}
      <ConfirmationModal/>
    </Box>
  );
};

export default DetailsFooter;