import { configureStore } from "@reduxjs/toolkit";
import loginSlice from "./Login/loginSlice";
import notificationSlice from "./Notification/notificationSlice";
import portalSlice from "./Portal/portalSlice";
import detailsSlice from "./Details/detailsSlice";
import modalSlice from "./modal/modalSlice";
import cashAmountSlice from "./CashAmount/cashAmountSlice";

export const store = configureStore({
  reducer: {
    portal: portalSlice,
    details: detailsSlice,
    login: loginSlice,
    notification: notificationSlice,
    modal: modalSlice,
    cashAmount: cashAmountSlice,
  },

});

store.subscribe(() => console.log(store.getState()));

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
export type GetState = typeof store.getState

