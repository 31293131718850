import axios, { AxiosInstance } from "axios";
import { renewToken } from "../utils/userUtil";

const httpClient = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_URL}`,
});


const retryApi: AxiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_URL}`,
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    Accept: "application/json",
  }
});

httpClient.interceptors.response.use(response => response,
  async (error: Error | any) => {
    const { config } = error;
    const refreshToken = localStorage.getItem('refresh_token')

    if (error.response.status !== 401 || !refreshToken || config._retry) {
      return Promise.reject(error);
    }
    config._retry = true;

    await renewToken(refreshToken);
    const accessToken = localStorage.getItem('access_token');
    config.headers = {
      ...config.headers,
      authorization: `Bearer ${accessToken}`,
    }
    return axios(config);
    
  });


export default httpClient;
