import * as yup from "yup";
import { t } from 'i18next'


export const loginSchema = () => {
  return yup.object({
    username: yup
      .string()
      .max(15, `${t("errors.usernameLength")}`)
      .required(`${t("errors.usernameReq")}`),

    password: yup
      .string()
      .min(4, `${t("errors.passwordMin")}`)
      .max(16, `${t("errors.passwordMax")}`)
      .required(`${t("errors.passwordReq")}`),
  });
}