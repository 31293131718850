import React from "react";
import { Box, Typography } from "@mui/material";
import style from "./PaymentInfo.module.css";
import { useAppSelector } from "../../../hooks/redux";
import { selectPaymentInfo } from "../../../store/Details/selectors";
import { useTranslation } from "react-i18next";
import { normalizeCoinType, normalizeCurrencyType } from "../../../helpers";
import Loader from "../../Shared/Loader/Loader";


const PaymentInfo = () => {
  const info = useAppSelector(selectPaymentInfo);
  const { t } = useTranslation();

const displayPaymentWithLoader = () => {
  return (
    info.paymentType === "buy" ? <>
        <Typography fontWeight={600} fontSize={"24px"}>A customer should pay:</Typography>
        <Typography color={'#F7C22B'} fontWeight={600} fontSize={"56px"}>{normalizeCurrencyType(info.currency)} {info.cash_amount} </Typography>
        <Typography fontWeight={600} fontSize={"24px"}>to recieve {(info.coins_amount).toLocaleString("en-US", { minimumFractionDigits: 3 })} {normalizeCoinType(info.coin)}</Typography>
      </> :
      <>
        <Typography fontWeight={600} fontSize={"24px"}>A customer should recieve:</Typography>
        <Typography color={'#F7C22B'} fontWeight={600} fontSize={"56px"}>{(info.coins_amount).toLocaleString("en-US", { minimumFractionDigits: 3 })} {normalizeCoinType(info.coin)}</Typography>
        <Typography fontWeight={600} fontSize={"24px"}>for his {normalizeCurrencyType(info.currency)} {info.cash_amount}</Typography>
      </> 
    );
}

  return (
    <Box className={style.container}>
      <Box className={style.box}>
          {displayPaymentWithLoader()}
      </Box>
    </Box>
  );
};

export default PaymentInfo;