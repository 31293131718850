

export const endpoints = {
  auth: {
    login: () => `/otc-api/token/`,
    refreshToken: () => `/otc-api/token/refresh/`,
  },
  payment: {
    getPaymentList: () => `/otc-api/payments/`,
    getPaymentDetails: (token: string) => `/otc-api/payments/${token}`,
    finishPayment: (token: string, operation: string) => `/otc-api/payments/${token}/finish/${operation}/`,
  },
  cashAmount: {
    getCashAmount: () => `/otc-api/available-cash/`,
    updateCashAmount: () => `/otc-api/available-cash/`,
    getHistory: () => `/otc-api/available-cash/log/`,
  },
};
