import * as yup from "yup";
import { t } from 'i18next'


export const cashAmountSchema = () => {
  return yup.object({
    cashAmount: yup
      .string()
      .required(`${t("errors.cashAmountReq")}`),
  });
}