import React from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Loader from "../../Shared/Loader/Loader";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../hooks/redux";
import style from "./../AmountHistory.module.css";
import {
  selectCashAmountHistory,
  selectCashAmountLoading,
} from "../../../store/CashAmount/selectors";
import {
  historySourceCheck,
  normalizeCashSnapshot,
  normalizeUpdateAmount,
} from "../../../helpers";
import moment from "moment";
import HistoryFooter from "./HistoryFooter";

const HistoryTable = () => {
  const historyList = useAppSelector(selectCashAmountHistory);
  const loading = useAppSelector(selectCashAmountLoading);
  const { t } = useTranslation();

  const paymentTypeColor = (type: string) => {
    if (type === "buy") return style.buyColor;
    if (type === "sell") return style.sellColor;
    else return style.manualSource;
  };

  const renderTableBody = () => {
    return historyList.map((row) => (
      <TableRow key={row.time_updated}>
        <TableCell>{historySourceCheck(row.source)}</TableCell>
        <TableCell>
          {moment(row.time_updated).format("DD.MM.YYYY HH:mm")}
        </TableCell>
        <TableCell>
          <Box className={paymentTypeColor(row.source)}>
            {normalizeUpdateAmount(row.source, row.update_amount)}
          </Box>
        </TableCell>
        <TableCell>{normalizeCashSnapshot(row.cash_snapshot)}</TableCell>
      </TableRow>
    ));
  };

  const emptyTable = () => {
    return (
      <Box className={style.empty_container}>
        <Typography fontSize={"16px"} fontWeight={500}>
          There is no records yet.
        </Typography>
      </Box>
    );
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Box className={style.mainContainer}>
      <TableContainer component={Paper} className={style.table_container}>
        <Table
          stickyHeader
          sx={{ minWidth: 714, maxWidth: 1616 }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell>{t("table.source")}</TableCell>
              <TableCell>{t("table.time_updated")}</TableCell>
              <TableCell>{t("table.update_amount")}</TableCell>
              <TableCell>{t("table.cash_register_state")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{renderTableBody()}</TableBody>
        </Table>
        {!historyList.length ? emptyTable() : null}
      </TableContainer>
      <HistoryFooter />
    </Box>
  );
};

export default HistoryTable;
