import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "../index";
import api from "../../api";
import { handleError } from "../../utils/errorsUtil";


type initialStateType = {
  payment: any[]
  payments: any
  loading: boolean
  finishLoading: boolean
  can_be_finished: boolean
}

const initialState: initialStateType = {
  payment: [],
  payments: {},
  loading: false,
  finishLoading: false,
  can_be_finished: false,
};

const detailsSlice = createSlice({
  name: "detailsSlice",
  initialState,
  reducers: {
    setPayment: (state, { payload }) => {
      state.payment = payload;
    },
    setProducts: (state, { payload }) => {
      state.payments = payload;
    },
    setLoading: (state, { payload }: { payload: boolean }) => {
      state.loading = payload;
    },
    setFinishLoading: (state, { payload }: { payload: boolean }) => {
      state.finishLoading = payload;
    },
    setCanBeFinished: (state, { payload }: { payload: boolean }) => {
      state.can_be_finished = payload
    }
  },
});

const { setPayment, setProducts, setLoading, setFinishLoading, setCanBeFinished } = detailsSlice.actions;
export default detailsSlice.reducer;

export const getPaymentDetails = (token: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setLoading(true));
    const result = await api.getPaymentDetails(token);
    if (result) {
      const paymentInfo = [
        { name: "Payment token", id: result.token },
        { name: "Customer phone number", id: result.customer },
      ] || [];

      dispatch(setPayment(paymentInfo));
      dispatch(setProducts(result));
      dispatch(setCanBeFinished(result.can_be_finished))
      dispatch(setLoading(false));
      return {
        res: true,
      };
    }
  }
  catch (error) {
    dispatch(handleError(error));
    dispatch(setLoading(false));
    return {
      res: false,
      error: error,
    };
  }
};

export const finishPayment = (token: string, operation: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setFinishLoading(true));
    const result = await api.finishPaymentReq(token, operation);
    dispatch(setFinishLoading(false));
    if (result) {
      return true;
    }
  }
  catch (error) {
    dispatch(setFinishLoading(false));
    dispatch(handleError(error));
    return false;
  }
};