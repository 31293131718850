import React from "react";
import RoutesProvider from "./routes/RoutesProvider";
import { ThemeProvider } from "@mui/material";
import { theme } from "./utils/theme";
import { useAuth } from "./hooks/useAuth";

function App() {
  useAuth();
  return (
    <ThemeProvider theme={theme}>
      <RoutesProvider />
    </ThemeProvider>
  );
}

export default App;
