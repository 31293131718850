import React, { useCallback, useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { selectPaymentInfo } from "../../../store/Details/selectors";
import CustomButton from "../../Shared/CustomButton/CustomButton";
import style from "./ConfirmationModal.module.css";
import { useTranslation } from "react-i18next";
import { normalizeCoinType, normalizeCurrencyType } from "../../../helpers";
import { useParams } from "react-router-dom";
import { finishPayment } from "../../../store/Details/detailsSlice";
import { selectPaymentConfirmation } from "../../../store/modal/selectors";
import { openConfirmationModal } from "../../../store/modal/modalSlice";


const ConfirmationModal = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const info = useAppSelector(selectPaymentInfo);
  const open = useAppSelector(selectPaymentConfirmation)
  const { t } = useTranslation();


  const closeModal = useCallback(() =>{
    dispatch(openConfirmationModal(false))
  }, [])

  const finishSelectedPayment = useCallback(async () => {
    await dispatch(finishPayment(params?.token || "", info.payment_type));
    closeModal();
  }, []) 

  const renderConfirmationDetails = () => {
    let recieverText = '';
    let inferency = '';

    if (info.payment_type === "buy") {
      recieverText = `Are you sure you have received ${normalizeCurrencyType(info.currency)}${info.cash_amount} from the customer?`;
      inferency = `By confirming it ${(info.coins_amount).toLocaleString("en-US", { minimumFractionDigits: 3 })} ${normalizeCoinType(info.coin)} will be sent to him`;
    }
    if (info.payment_type === "sell") {
      recieverText =  `Are you sure the customer have received ${normalizeCurrencyType(info.currency)}${info.cash_amount}?`;
      inferency = `By confirming it the payment will be closed`;
    }
     return (
       <Box>
        <DialogContentText color={'#FFFFFF'} lineHeight={'40px'} fontSize={32} fontWeight={500}>
         {recieverText}
        </DialogContentText>
        <DialogContentText marginTop={'16px'} color={'#FFFFFF6A'} fontSize={20} fontWeight={400}>
          {inferency}
        </DialogContentText>
      </Box>
    );
  };

  const renderButtons = () => {
    return (
      <Box className={style.buttons_box}>
        <CustomButton 
        height="48px"
        width="101px" 
        onClick={closeModal}
        variant={"neutral"}>
          {t("buttons.no")}
        </CustomButton>

        <CustomButton 
        onClick={finishSelectedPayment}
        height="48px" 
        width="138px" 
        variant="contained"
        color={info.payment_type === "buy" ? "secondary" : "primary"}>
          {t("buttons.confirm")}
        </CustomButton>
      </Box>
    );
  };

  return (
    <Dialog PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }} open={open} onClose={closeModal}>
      <DialogContent className={style.container}>
        <Box className={style.box}>
          {renderConfirmationDetails()}
          {renderButtons()}
        </Box>
      </DialogContent>
    </Dialog>
  );
};
export default ConfirmationModal;
