import React, { useState } from "react";
import { Box, MenuItem, Select } from "@mui/material";
import styles from "./Header.module.css";
import Logo from "../../assets/logo/logo.svg";
import CustomButton from "../Shared/CustomButton/CustomButton";
import { logout } from "../../utils/userUtil";
import { useAuth } from "../../hooks/useAuth";
import { Link } from "react-router-dom";
import { RouteConstants } from "../../routes/routes-constant";
import { useTranslation } from "react-i18next";
import EnIcon from "../../assets/en-icon.png";
import EsIcon from "../../assets/es-icon.png";
import { useResponsive } from "../../hooks/useResponsive";


const langs: any = {
  en: { nativeName: "EN", src: EnIcon },
  // es: { nativeName: "ES", src: EsIcon },
};

const Header = () => {
  const { t, i18n } = useTranslation();
  const { isLoggedIn } = useAuth();
  const { isMobile } = useResponsive();

  const renderLogoutBtn = () => {
    if (isLoggedIn) return (
      <CustomButton
        variant="outlined"
        width={"130px"}
        onClick={logout}
        style={{ whiteSpace: "nowrap" }}
      >
        {t("buttons.logout")}
      </CustomButton>
    );
  };

  return (
    <Box className={styles.header}>
      <Box
        className={styles.logo}
      >
        <Link
          style={{ display: "flex" }}
          to={RouteConstants.PORTAL_PATH}>
          <img src={Logo} alt="cashier portal" />
        </Link>
      </Box>
      {renderLogoutBtn()}
    </Box>
  );
};

export default Header;