import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "../index";
import api from "../../api";
import { handleError } from "../../utils/errorsUtil";


type initialStateType = {
  list: any[]
  loading: boolean
}

const initialState: initialStateType = {
  list: [],
  loading: false,
};

const portalSlice = createSlice({
  name: "portalSlice",
  initialState,
  reducers: {
    setList: (state, { payload }) => {
      state.list = payload;
    },
    setPortalLoading: (state, { payload }) => {
      state.loading = payload;
    },
  },
});

const { setList, setPortalLoading } = portalSlice.actions;
export default portalSlice.reducer;

export const getOrderList = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(setPortalLoading(true));
    const result = await api.getPaymentList();
    dispatch(setPortalLoading(false));
    if (result) {
      dispatch(setList(result.results));
    }
  }
  catch(error) {
    dispatch(setPortalLoading(false));
    return dispatch(handleError(error));
  }  
};