import { createTheme } from "@mui/material/styles";


export const theme = createTheme({
  typography: {
    "fontFamily": "'Roboto', sans-serif",
    allVariants: {
      color: "#ffffff",
    },
    button: {
      textTransform: "none",
      fontWeight: 500,
      fontSize: "16px",
    },
  },
  shape: {
    borderRadius: 8,
  },
  palette: {
    primary: {
      main: "#F7C22B",
    },
    secondary: {
      main: '#05FFA5',
    }
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: "back" },
          style: {
            border: "2px solid #667080",
            color: "#667080",
          },
        } as any,
        {
          props: { variant: "neutral" },
          style: {
            backgroundColor: "#262834",
            color: "#FFFFFF"
          },
        } as any,
      ],
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "12px",
          color: "#1E202C",
          backgroundColor: "#FFFFFF",
          borderRadius: "8px",
          padding: "8px 12px"
        },
      },
    },
    MuiFormControl: {
      variants: [
        {
          props: { variant: "standard" },
          style: {
            color: "#ffffff",
            opacity: 1,
            borderRadius: 8,
            "& label.Mui-focused": { // Label Focus Text
              color: "#000000",
            },
            "& .Mui-error label": {
              color: "#E9757E!important",
              borderColor: "#E9757E!important",
            },
            "& input": {
              color: "#ffffff",
              opacity: 0.6,
              paddingLeft: 16,
              paddingTop: 12,
              paddingBottom: 12,
              borderRadius: 8,
            },
            "& input::placeholder": {
              color: "#ffffff",
              opacity: 1,
            },
            "& .MuiInputLabel-root": {
              color: "#000000",
            },
            "& .MuiInput-underline:after": { // ...
              // borderBottomColor: 'pink',
              color: "#000000",
            },
            "& .MuiInput-underline:before": { // ...
              // borderBottomColor: 'pink',
              color: "#000000",
            },
            "& .MuiInputBase-root": { // Border
              color: "#000000",
              backgroundColor: "#292B37",
              borderRadius: 8,
              "& fieldset": {
                borderColor: "transparent",
                borderRadius: 8,
              },
              "&:hover fieldset": { // ...
                borderColor: "transparent",
              },
              "&.Mui-focused fieldset": { // Border Focus
                borderColor: "transparent",
              },
              "&.Mui-error fieldset": {
                borderColor: "#E9757E!important",
              },
            },
          },
        },
        {
          props: { variant: "outlined" },
          style: {
            color: "#ffffff",
            opacity: 0.6,
            "& label.Mui-focused": { // Label Focus Text
              color: "#000000",
            },
            "& .Mui-error label": {
              color: "#E9757E!important",
              borderColor: "#E9757E!important",
            },
            "& input": {
              color: "#ffffff",
              opacity: 0.6,
              paddingLeft: 16,
              paddingTop: 12,
              paddingBottom: 12,
            },
            "& input::placeholder": {
              color: "#ffffff",
              opacity: 0.6,
            },
            "& .MuiInputLabel-root": {
              color: "#000000",
            },
            "& .MuiInput-underline:after": { // ...
              // borderBottomColor: 'pink',
              color: "#000000",
            },
            "& .MuiInput-underline:before": { // ...
              // borderBottomColor: 'pink',
              color: "#000000",
            },
            "& .MuiInputBase-root": { // Border
              color: "#000000",
              backgroundColor: "#292B37",
              "& fieldset": {
                borderColor: "transparent",
                borderRadius: 8,
              },
              "&:hover fieldset": { // ...
                borderColor: "transparent",
              },
              "&.Mui-focused fieldset": { // Border Focus
                borderColor: "transparent",
              },
              "&.Mui-error fieldset": {
                borderColor: "#E9757E!important",
              },
            },
          },
        },
      ],
      styleOverrides: {
        root: {},
      },
    },
    MuiSelect: {
      variants: [
        {
          props: { variant: "outlined" },
          style: {
            "&.MuiInputBase-root": { // Border
              "& fieldset": {
                border: "none"
              },
              "&:hover fieldset": { // ...
                borderColor: "rgba(102, 112, 128, 80%)",
              },
              "&.Mui-focused fieldset": { // Border Focus
                borderColor: "rgba(102, 112, 128, 80%)",
              },
            },
            "& .MuiSvgIcon-root": {
              color: "#fff",
            },
            "&.MuiPopover-root .MuiPaper-root": {
              color: "red",
            },
          },
        },
      ],
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {},
        focused: {},
      },
    },
  },

});