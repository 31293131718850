import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "../index";
import api from "../../api";
import { handleError } from "../../utils/errorsUtil";


type initialStateType = {
    history: any[]
    loading: boolean
    cashAmount: string
    cashAmountCurrency: string
}

const initialState: initialStateType = {
    history: [],
    loading: false,
    cashAmount: '0',
    cashAmountCurrency: '',
}

const cashAmountSlice = createSlice({
    name: "cashAmountSlice",
    initialState,
    reducers: {
        setHistory: (state, { payload }) => {
            state.history = payload;
        },
        setCashAmountLoading: (state, { payload }) => {
            state.loading = payload;
        },
        setCashAmount: (state, { payload }) => {
            state.cashAmount = payload;
        },
        setCashAmountCurrency: (state, { payload }) => {
            state.cashAmountCurrency = payload;
        },
    }
});

export const { setHistory, setCashAmountLoading, setCashAmount, setCashAmountCurrency } = cashAmountSlice.actions;
export default cashAmountSlice.reducer;

export const getCashAmountHistory = () => async (dispatch: AppDispatch) => {
    try {
        dispatch(setCashAmountLoading(true));
        const result = await api.cashHistory();
        dispatch(setCashAmountLoading(false));
        if (result) {
            dispatch(setHistory(result.results));
        }
    }
    catch (error) {
        dispatch(setCashAmountLoading(false));
        return dispatch(handleError(error));
    }
};

export const updateCashAmount = (cashAmount: string) => async (dispatch: AppDispatch) => {
    try {
        const result = await api.updateCashAmount(cashAmount);
        if (result) {
            dispatch(setCashAmount(result.available_amount));
        };
    }
    catch (error) {
        return dispatch(handleError(error));
    }
};

export const getCashAmount = () => async (dispatch: AppDispatch) => {
    try {
        const result = await api.getCashAmount();
        if (result) {
            dispatch(setCashAmount(result.available_amount));
            dispatch(setCashAmountCurrency(result.currency));
        };
    }
    catch (error) {
        return dispatch(handleError(error));
    }
};
