import httpClient from "./httpClient";
import { endpoints } from "./constants";
import { ApiHeaders } from "./types";
import { ILogin } from "../models/login.interface";


const API = {

  loginRequest<T>(data: ILogin): Promise<T> {
    return doHttpCall<T>("POST", endpoints.auth.login(), { payload: data });
  },

  refreshAccessToken(refreshToken: string) {
    return doHttpCall("POST", endpoints.auth.refreshToken(), { payload: { refresh: refreshToken } });
  },

  getPaymentList() {
    return doHttpCall("GET", endpoints.payment.getPaymentList());
  },

  getPaymentDetails(token: string) {
    return doHttpCall("GET", endpoints.payment.getPaymentDetails(token));
  },

  finishPaymentReq(token: string, operation: string) {
    return doHttpCall("POST", endpoints.payment.finishPayment(token, operation));
  },
  getCashAmount() {
    return doHttpCall("GET", endpoints.cashAmount.getCashAmount());
  },
  updateCashAmount(cashAmount: string) {
    return doHttpCall("PATCH", endpoints.cashAmount.updateCashAmount(), { payload: { available_amount: cashAmount } });
  },
  cashHistory() {
    return doHttpCall("GET", endpoints.cashAmount.getHistory());
  },

};

export default API;

async function doHttpCall<T = any>(
  method: string,
  url: string,
  options?: {
    payload?: Record<string, unknown> | ILogin,
    params?: Record<string, unknown>,
  },
): Promise<T> {
  try {
    const headers: ApiHeaders = {
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
    };
    const access_token = localStorage.getItem("access_token");

    if (access_token) {
      headers["Authorization"] = `Bearer ${access_token}`;
    }
    const result = await httpClient.request({
      headers,
      url,
      method,
      data: options?.payload,
      params: options?.params,
    });

    if (result?.status === 200) {
      return result.data;
    }
    throw new Error();
  } catch (error: Error | any) {
    throw error;
  }
}
