import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "../index";

export type notificationProps = {
  message: null,
  visible: boolean,
  notificationType: string,
}

const initialState: notificationProps = {
  message: null,
  visible: false,
  notificationType: "info",
};

export const notificationSlice = createSlice({
  name: "notificationSlice",
  initialState,
  reducers: {
    show: (state, { payload }) => ({
      ...state,
      message: payload.message,
      visible: true,
      notificationType: payload.notificationType,
    }),
    hideNotification: () => ({ ...initialState }),
  },
});

const { show } = notificationSlice.actions;
export const { hideNotification } = notificationSlice.actions;

export const showNotification = (message: string, notificationType?: string) => async (dispatch: AppDispatch) => {
  dispatch(show({ message, notificationType }));
};

export default notificationSlice.reducer;
