import React from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import style from "./PortalTable.module.css";
import moment from "moment";
import { ViewButton } from "../../Shared/Button";
import { useAppSelector } from "../../../hooks/redux";
import { useNavigate } from "react-router-dom";
import { details_path } from "../../../routes/routes-constant";
import {
  selectPaymentList,
  selectPortalLoading,
} from "../../../store/Portal/selectors";
import Loader from "../../Shared/Loader/Loader";
import { useTranslation } from "react-i18next";
import { normalizeCoinType, normalizeCurrencyType } from "../../../helpers";

const PortalTable = () => {
  const paymentList = useAppSelector(selectPaymentList);
  const navigate = useNavigate();
  const loading = useAppSelector(selectPortalLoading);
  const { t } = useTranslation();

  const handleClick = (token: string) => {
    navigate(details_path(token));
  };

  const paymentTypeColor = (type: string) => {
    if (type === "buy") return style.buyColor;
    else return style.sellColor;
  };

  const emptyTable = () => {
    return (
      <Box className={style.empty_container}>
        <Typography fontSize={"16px"} fontWeight={500}>
          There is no records yet.
        </Typography>
      </Box>
    );
  };

  const renderTableBody = () => {
    return paymentList.map((row) => (
      <TableRow key={row?.token}>
        <TableCell>{row.customer}</TableCell>
        <TableCell>
          <Box className={paymentTypeColor(row.payment_type)}>
            {" "}
            {row.payment_type.toUpperCase()}
          </Box>
        </TableCell>
        <TableCell>
          {normalizeCurrencyType(row.currency)} {row.cash_amount}
        </TableCell>
        <TableCell>
          {row.coins_amount.toLocaleString("en-US", {
            minimumFractionDigits: 3,
          })}{" "}
          {normalizeCoinType(row.coin)}
        </TableCell>
        <TableCell>{moment(row.expires).format("DD.MM.YYYY HH:mm")}</TableCell>
        <TableCell>
          <ViewButton
            onClick={() => handleClick(row.token)}
            children={t("buttons.view")}
          />
        </TableCell>
      </TableRow>
    ));
  };

  const renderTableOrLoader = () => {
    if (loading) return <Loader />;
    return (
      <TableContainer component={Paper} className={style.table_container}>
        <Table
          stickyHeader
          sx={{ minWidth: 714, maxWidth: 1616 }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell>{t("table.customer")}</TableCell>
              <TableCell>{t("table.type")}</TableCell>
              <TableCell>{t("table.cashAmount")}</TableCell>
              <TableCell>{t("table.coinsAmount")}</TableCell>
              <TableCell>{t("table.expirationTime")}</TableCell>
              <TableCell>{t("table.details")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{renderTableBody()}</TableBody>
        </Table>
        {(!paymentList.values.length) ? emptyTable() : null}
      </TableContainer>
    );
  };

  return <>{renderTableOrLoader()}</>;
};

export default PortalTable;
