import React, { useCallback } from "react";
import { Box, TextField, Tooltip, Typography } from "@mui/material";
import style from "../Portal.module.css";
import QrIcon from "../../../assets/qr-code-icon.svg";
import CustomButton from "../../Shared/CustomButton/CustomButton";
import BoxIcon from "../../../assets/box-icon.svg";
import TooltipIcon from "../../../assets/tooltip-amount.svg";
import { useNavigate } from "react-router-dom";
import { RouteConstants, details_path } from "../../../routes/routes-constant";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { selectLoading } from "../../../store/Details/selectors";
import { getPaymentDetails } from "../../../store/Details/detailsSlice";
import { useFormik } from "formik";
import { IPaymentToken } from "../../../models/paymentToken.interface";
import { validationSchema } from "./validationSchema";
import { useTranslation } from "react-i18next";
import {
  selectCashAmount,
  selectCashAmountCurrency,
} from "../../../store/CashAmount/selectors";
import { openCashAmountModal } from "../../../store/modal/modalSlice";
import CashAmountModal from "../CashAmountModal/CashAmountModal";
import { normalizeCurrencyType } from "../../../helpers";

type PaymentTokenProps = {
  openQrReader: () => void;
};

const PaymentToken = ({ openQrReader }: PaymentTokenProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const loading = useAppSelector(selectLoading);
  const cashAmount = useAppSelector(selectCashAmount);
  const currency = useAppSelector(selectCashAmountCurrency);
  const { t } = useTranslation();

  const handleClick = async (value: IPaymentToken, { setFieldError }: any) => {
    const paymentToken = value.paymentToken;
    const { res } = await dispatch(getPaymentDetails(paymentToken));
    if (!res) return setFieldError("paymentToken", t("errors.paymentTokenTwo"));
    navigate(details_path(paymentToken));
  };

  const viewHistory = () => {
    navigate(RouteConstants.CASH_AMOUNT_HISTORY_PATH);
  };

  const openAmountModal = useCallback(() => {
    dispatch(openCashAmountModal(true));
  }, []);

  const formik = useFormik({
    initialValues: {
      paymentToken: "",
    },
    validationSchema: validationSchema,
    onSubmit: handleClick,
  });

  const tooltipTitle = `This amount reflects your cash register amount. It will be automatically increased/decreased after each buy/sell payment.`;

  return (
    <Box className={style.mainContainer}>
      <Box className={style.container}>
        <Box className={style.block}>
          <img src={QrIcon} alt="qr-code" />
          <CustomButton
            variant={"contained"}
            height="56px"
            width={"189px"}
            fontWeight={700}
            onClick={openQrReader}
          >
            {t("buttons.scan")}
          </CustomButton>
        </Box>
        <Box className={style.block}>
          <img src={BoxIcon} alt="box" />
          <Box
            component={"form"}
            onSubmit={formik.handleSubmit}
            noValidate
            className={style.orderBlock}
          >
            <TextField
              fullWidth
              id="paymentToken"
              name="paymentToken"
              type="text"
              placeholder={"Payment token"}
              value={formik.values.paymentToken.trim()}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={Boolean(
                formik.errors.paymentToken && formik.touched.paymentToken
              )}
              helperText={
                formik.touched.paymentToken && formik.errors.paymentToken
                  ? formik.touched.paymentToken && formik.errors.paymentToken
                  : " "
              }
            />
            <CustomButton
              variant={"contained"}
              height="48px"
              fullWidth
              type={"submit"}
              style={{ color: "#000000" }}
              loading={loading}
              disabled={loading}
            >
              {t("buttons.submit")}
            </CustomButton>
          </Box>
        </Box>
        <Box className={style.amountBlock}>
          <Box className={style.headingWithTooltip}>
            <Typography marginRight={"10px"} fontSize={"12px"} fontWeight={500}>
              Available cash amount
            </Typography>
            <Tooltip title={tooltipTitle} placement="right">
              <img src={TooltipIcon} alt="tooltip" />
            </Tooltip>
          </Box>
          <Typography marginBottom={"16px"} fontSize={"32px"} fontWeight={600}>
            {normalizeCurrencyType(currency)} {cashAmount}
          </Typography>
          <Box className={style.buttonsBlock}>
            <CustomButton
              variant={"contained"}
              height="48px"
              width={"132px"}
              fontWeight={700}
              onClick={openAmountModal}
            >
              {t("buttons.update")}
            </CustomButton>
            <CustomButton
              variant={"neutral"}
              height="48px"
              width={"173px"}
              fontWeight={700}
              onClick={viewHistory}
            >
              {t("buttons.show_history")}
            </CustomButton>
          </Box>
        </Box>
      </Box>
      <CashAmountModal />
    </Box>
  );
};

export default PaymentToken;
