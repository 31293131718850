import api from "../api";
import { RouteConstants } from "../routes/routes-constant";

export const logout = () => {
  localStorage.clear();
  window.location.href = RouteConstants.LOGIN_PATH;
  console.log('logout')
};

export const renewToken = async (refreshToken: string) => {
  try {    
    const result = await api.refreshAccessToken(refreshToken);

    if (result) {
      localStorage.setItem("access_token", result.access);
      return true;
    }
  }
  catch(error: Error | any){
    logout();
    throw error;
  }
}