import React, { useEffect } from "react";
import { useAppDispatch } from "../../hooks/redux";
import { getCashAmountHistory } from "../../store/CashAmount/cashAmountSlice";
import CashAmountHistory from "../../components/CashAmountHistory/CashAmountHistory";

const CashAmountHistoryPage = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getCashAmountHistory());
    }, []);

    return (
        <CashAmountHistory/>
    );
}

export default CashAmountHistoryPage;