import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "../index";
import api from "../../api";
import { ILogin } from "../../models/login.interface";
import { handleError } from "../../utils/errorsUtil";


type initialStateType = {
  isLoading: boolean
}

const initialState: initialStateType = {
  isLoading: false,
};

const loginSlice = createSlice({
  name: "loginSlice",
  initialState,
  reducers: {
    setLoadingLogin(state, { payload }: { payload: boolean }) {
      state.isLoading = payload;
    },
  },
});

const { setLoadingLogin } = loginSlice.actions;
export default loginSlice.reducer;

export const login = (data: ILogin) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setLoadingLogin(true));
    const result = await api.loginRequest<{ access: string; refresh: string }>(data);
    dispatch(setLoadingLogin(false));
    if (result) {
      localStorage.setItem("access_token", result.access);
      localStorage.setItem("refresh_token", result.refresh);
      return true;
    }
  }
  catch(error: Error | any){
    dispatch(setLoadingLogin(false));
    return dispatch(handleError(error));
  }
};
