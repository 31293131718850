import React from "react";
import { Box } from "@mui/material";
import CustomButton from "../../Shared/CustomButton/CustomButton";
import { useNavigate } from "react-router-dom";
import { RouteConstants } from "../../../routes/routes-constant";
import ArrowIcon from "../../../assets/arrow-left.svg";
import style from "./../AmountHistory.module.css";
import { useTranslation } from "react-i18next";

const HistoryFooter = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const goToHome = () => navigate(RouteConstants.PORTAL_PATH);

  return (
    <Box className={style.footerContainer}>
      <CustomButton
        variant={"back"}
        height="48px"
        width={"128px"}
        onClick={goToHome}
      >
        <img style={{ marginRight: "10px", height: "24px" }} src={ArrowIcon} alt="arrow" />
        {t("buttons.back")}
      </CustomButton>
    </Box>
  );
};

export default HistoryFooter;