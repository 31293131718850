import React from "react";
import HistoryTable from "./HistoryTable/HistoryTable";
import Breadcrumbs from "../Details/Breadcrumbs/Breadcrumbs";
import { Box } from "@mui/material";
import HistoryFooter from "./HistoryTable/HistoryFooter";

const CashAmountHistory = () => {
  return (
    <Box mt={"30px"} p={"0 15px"}>
      <Breadcrumbs />
      <HistoryTable />
    </Box>
  );
};

export default CashAmountHistory;
