import React, { PropsWithChildren } from "react";
import { Button, ButtonProps, CircularProgress } from "@mui/material";

type CustomButtonProps = {
  height?: string;
  width?: string;
  loading?: boolean;
  fontWeight?: number;
  backgroundColor?: string;
} & PropsWithChildren &
  ButtonProps;

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    back: true;
    neutral: true;
  }
}

const CustomButton = (props: CustomButtonProps) => {
  const {loading, ...params} = props;
  const { children, height, width, fontWeight } = params;

  const renderWithLoader = () => {
    if (loading) return <CircularProgress color={"primary"} size={20} />;
    return children;
  };

  return (
    <Button
      {...params}
      sx={{
        height: height,
        width: width,
        fontWeight: fontWeight,
      }}
    >
      {renderWithLoader()}
    </Button>
  );
};

export default CustomButton;
