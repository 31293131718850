export class RouteConstants {
  static readonly LOGIN = "login";
  static readonly PORTAL = "portal";
  static readonly DETAILS = "details";
  static readonly CASH_AMOUNT_HISTORY = "history";

  static readonly LOGIN_PATH = `/${RouteConstants.LOGIN}`;
  static readonly PORTAL_PATH = `/${RouteConstants.PORTAL}`;
  static readonly DETAILS_PATH = `/${RouteConstants.DETAILS}/:token`;
  static readonly CASH_AMOUNT_HISTORY_PATH = `/${RouteConstants.CASH_AMOUNT_HISTORY}`;
}

export const details_path = (token = ':token') => `/${RouteConstants.DETAILS}/${token}`