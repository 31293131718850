import React, { useState } from "react";
import { Backdrop, Box, Fade, IconButton, Modal, Typography } from "@mui/material";
import QrReader from "react-web-qr-reader";
import styles from "./ScanQr.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { details_path } from "../../../routes/routes-constant";
import { handleError } from "../../../utils/errorsUtil";
import { useAppDispatch } from "../../../hooks/redux";
import { parseQrData } from "../../../helpers";
import { useResponsive } from "../../../hooks/useResponsive";
import { useTranslation } from "react-i18next";
import SwitchIcon from "../../../assets/switch-icon.svg";

type ScanQrProps = {
  setIsQrOpen: (e: boolean) => void;
  isQrOpen: boolean
}


const ScanQr = ({ setIsQrOpen, isQrOpen }: ScanQrProps) => {
  const [facingMode, setFacingMode] = useState<"environment" | "user">("environment");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isMobile, isTablet } = useResponsive();
  const { t } = useTranslation();

  const previewStyle = {
    height: !isMobile ? 480 : 335,
    width: !isMobile ? 480 : 335,
  };

  const handleScan = (result: any) => {
    if (result) {
      const data = dispatch(parseQrData(result?.data));
      if (data) {
        setIsQrOpen(false);
        navigate(details_path(data));
      }
    }
  };

  const handleErrors = (error: any) => {
    console.log(error);
    dispatch(handleError(error));
  };

  const closeQrReader = () => setIsQrOpen(false);

  const switchCamera = () => {
    setFacingMode(facingMode === "environment" ? "user" : "environment");
  };

  const renderSwitchCamera = () => {
    if (isMobile || isTablet)
      return (
        <IconButton
          className={styles.switch}
          color="primary"
          component="label"
        >
          <img onClick={switchCamera} src={SwitchIcon} alt="switch" />
        </IconButton>
      );
  };

  return (
    <Modal
      open={isQrOpen}
      onClose={closeQrReader}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isQrOpen}>
        <Box className={styles.qrContainer}>
          <Box className={styles.scanBox}>
            <CloseIcon
              className={`${styles.close} cursor`}
              onClick={closeQrReader}
            />
            <QrReader
              onScan={handleScan}
              onError={handleErrors}
              style={previewStyle}
              facingMode={facingMode}
            />
            <Box className={styles.qrText}>
              <Typography fontSize={"12px"} fontWeight={500}>{t("qr.title")}</Typography>
              <Typography sx={{ opacity: "0.6" }} fontSize={"10px"}>{t("qr.subtitle")}</Typography>
            </Box>
            {renderSwitchCamera()}
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default ScanQr;